


























import { Component, Mixins } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'
import { isEqual } from 'lodash'

// components
import TableFooter from '@/components/_uikit/TableFooter.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ManagerDepositoryModule from '@/store/modules/manager/depository'
import { CourseFoldersStaffResource, ITableOptions } from '@/store/types'
// utils
import { tableFooterOptions } from '@/utils/constants'

@Component({
  components: {
    TableFooter,
  },
})
export default class TrainingFilesTable extends Mixins(NotifyMixin) {
  private get headers () {
    return [
      { cellClass: 'cell-width-8', text: '#', value: 'id' },
      { cellClass: 'cell-width-50', text: 'Месяц', value: 'title' },
      { cellClass: 'cell-width-12', text: 'Папки', value: 'foldersCount' },
      { cellClass: 'cell-width-12', text: 'Всего файлов', value: 'filesTotal' },
    ]
  }

  private courseID = this.$route.params.courseID

  private get trainingFilesMonths(): CourseFoldersStaffResource[] {
    return ManagerDepositoryModule.trainingFilesMonths.data
  }

  private get pagination() {
    return ManagerDepositoryModule.trainingFilesMonths.meta
  }

  private get filter() {
    return ManagerDepositoryModule.trainingFilesFilter
  }

  private set filter(filter: ITableOptions) {
    if (!isEqual(filter, this.filter) || this.trainingFilesMonths.length === 0) {
      ManagerDepositoryModule.setTrainingFilesFilter(filter)
      this.fetchTrainingFilesMonths()
    }
  }

  private footerOptions = tableFooterOptions

  private mounted() {
    this.fetchTrainingFilesMonths()
  }

  private handleRowClick(tableFooter: any) {
    this.$router.push({
      name: 'manager.bank.depository.courses.item.files.month',
      params: {
        courseID: this.$route.params.courseID,
        monthID: tableFooter.id.toString(),
      },
    })
  }

  @Bind
  @Debounce(300)
  private fetchTrainingFilesMonths() {
    ManagerDepositoryModule.fetchTrainingFilesMonths({ courseId: +this.courseID })
      .catch(this.notifyError)
  }
}
