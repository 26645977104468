var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"training-files mt-6"},[_c('VDataTable',{staticClass:"row-pointer data-table_clickable",attrs:{"headers":_vm.headers,"items":_vm.trainingFilesMonths,"options":_vm.filter,"server-items-length":_vm.pagination.total,"footer-props":_vm.footerOptions,"hide-default-footer":"","disable-sort":true},on:{"update:options":function($event){_vm.filter=$event},"click:row":_vm.handleRowClick,"update:items-per-page":function($event){_vm.filter.page = 1}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.id))])]}},{key:"item.foldersCount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:!item.foldersCount && 'grey--text'},[_vm._v(_vm._s(item.foldersCount))])]}},{key:"item.filesTotal",fn:function(ref){
var item = ref.item;
return [_c('span',{class:!item.filesTotal ? 'grey--text' : 'accent--text'},[_vm._v(_vm._s(item.filesTotal))])]}},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('TableFooter',{attrs:{"props":props,"filter":_vm.filter},on:{"update:filter":function($event){_vm.filter=$event}}})]}},{key:"no-data",fn:function(){return [_c('NoDataFound',{attrs:{"skin":"grey"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }